import classNames from 'classnames';
import { useId } from 'hooks';
import './index.css';

function Monogram({ highlight, className, ...props }) {
  const id = useId();
  const clipId = `monogram-clip-${id}`;

  return (
    <svg
      aria-hidden
      className={classNames('monogram', className)}
      width="50"
      height="50"
      viewBox="0 0 95 95"
      {...props}
    >
      <defs>
        <clipPath id={clipId}>
        <path d="M20.7 21.78q3.39 0 5.35.73 1.97.73 4.05 2.12 1.69 1.08 2.61 1.08.7 0 1.08-.81.39-.81.62-2.58h1.77q-.39 4.46-.39 15.71h-1.77q-.46-6.24-3.58-10.51-3.12-4.28-9.05-4.28-4.15 0-6.77 2.5Q12 28.25 12 32.17q0 2.93 1.35 5.09 1.35 2.15 3.42 3.69 2.08 1.54 5.93 3.93l2.24 1.39q4.85 3 7.66 5.27 2.81 2.27 4.5 5.2 1.7 2.92 1.7 6.85 0 7.01-4.62 10.82-4.62 3.81-11.71 3.81-3.69 0-6.04-.77t-4.51-2.08q-1.69-1.08-2.61-1.08-.7 0-1.08.81-.39.81-.62 2.58H5.84q.31-4.23.31-18.48h1.77q.54 7.78 3.85 12.63 3.31 4.85 10.16 4.85 4.16 0 7.09-2.58 2.92-2.58 2.92-7.66 0-4.31-2.88-7.32-2.89-3-9.13-6.77-4.54-2.77-7.35-4.93-2.81-2.16-4.58-5.2T6.23 35.1q0-6.47 4.15-9.89 4.16-3.43 10.32-3.43zm73.46.85v1.54q-2.7.15-4 .81-1.31.65-1.78 2.15-.46 1.5-.46 4.43v45.97h-1.77L53.81 27.55v40.66q0 3 .5 4.47.5 1.46 1.97 2.11 1.46.66 4.54.81v1.54q-2.77-.23-7.86-.23-4.62 0-7.16.23V75.6q2.7-.15 4.01-.81 1.31-.65 1.77-2.15.46-1.5.46-4.43V30.79q0-2.93-.46-4.24t-1.73-1.77q-1.27-.46-4.05-.61v-1.54q2.54.23 7.16.23 3.47 0 6.01-.23l27.18 41.88V31.56q0-3-.5-4.47-.5-1.46-1.96-2.11-1.47-.66-4.55-.81v-1.54q2.78.23 7.86.23 4.62 0 7.16-.23z"></path>

        </clipPath>
      </defs>
      <rect clipPath={`url(#${clipId})`} width="100%" height="100%" />
      {highlight && (
        <g clipPath={`url(#${clipId})`}>
          <rect className="monogram__highlight" width="100%" height="100%" />
        </g>
      )}
    </svg>
  );
}

export default Monogram;